import React from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';

import Img from 'gatsby-image';
import AnchorLink from 'react-anchor-link-smooth-scroll';

import Layout from '../components/layout/Layout';
import Card from '../components/Card';
import SplitSection from '../components/SplitSection';
import Callout from '../components/Callout';
import CalloutSimple from '../components/CalloutSimple';
import Seo from '../components/Seo';
import ArrowDown from '../components/ArrowDown';

import tick from '../assets/icons/tick.png'



export default () => {
  const title = 'Privacy Policy | Strio ';
  const description =
    "Strio's Privacy Policy";
  const url = 'https://strio.app/privacy';

  return (
    <Layout>
      <Seo title={title} description={description} url={url} />

      <div className="bg-lownight md:h-20 h-16" />
      <section className="flex justify-center relative pa-0 overflow-hidden mb-20">
        <div className="w-1/2">
          <h1 className="pb-4 lg:mt-10 text-2xl lg:text-3xl xl:text-4xl font-bold leading-none tracking-tight text-center">
            Privacy Policy
          </h1>
          <br/>
          <h2><span>Last updated: September 7, 2020</span></h2>
          <br/>
          <p><span>Strio knows that you care how information about you and your customers is used and shared, and we appreciate your trust that we will do so carefully and sensibly.</span></p>
          <p><span>This Privacy Policy describes how Strio or its Affiliates may collect or process Personal Data.</span></p>
          <p><span>The capitalized terms are defined in this Privacy Policy or in our Terms of Services.</span></p>
          <br/>
          <h2><strong>AMENDMENT AND UPDATE OF THE PRIVACY POLICY</strong></h2>
          <p><span>We may update this Privacy Policy at any time to adapt it to potential new practices and service offerings. In such a case, we will change the </span><span>"last updated"</span><span> date and we will indicate the date on which the changes have been made. We encourage you to review the potential updates of this Privacy Policy posted on the Site.</span></p>
          <br/>
          <h2><strong>DATA THAT CAN BE COLLECTED</strong></h2>
          <br/>
          <h3><strong>Data that you communicate to us</strong></h3>
          <p><span>We collect and process the data that you communicate to us in order to access the Site and/or to use our Services and in particular the following data:</span></p>
            <ul className="ml-6">
            <li><span>- Your contact details: first name, surname, email address, postal address;</span></li>
            <li><span>- The name of your company;</span></li>
            <li><span>- Your login details: user name (email address), password.</span></li>
            <li><span>- Any information you provide to us when you fill in forms on the Site.</span></li>
            </ul>
          <p><span>The mandatory nature of the information you shall provide on the Site and/or when using our Services is notified at the time of the collection and on the corresponding forms by asterisks.</span></p>
          <p><span>This data is collected by Strio within the framework of the following operations:</span></p>
            <ul className="ml-6">
            <li><span>- When creating your personal account at the time of your subscription on the Site;</span></li>
            <li><span>- When buying and using our Services;</span></li>
            <li><span>- When you request to receive specific information (by email, when you subscribe to our Newsletter etc.).</span></li>
            </ul>
          <p><span>Strio may send you emails to the email address you have provided, in order to send you technical or administrative information, to inform you of the evolution of the Services when necessary for the provision of the Services or to inform you about new services or features.</span></p>
          <p><span>We may also use the data we collect to answer your enquiries relating to the Services available on the Site. For example, we may use your contact details to answer your enquiries addressed to the customer service.</span></p>
          <br/>
          <h3><strong>Traffic data</strong></h3>
          <p><span>In order to improve our Site and our Services and to make them more efficient and more suited to your needs and tastes, our servers automatically collect technical data, among which some data might be personal data.</span></p>
          <p><span>Strio may, in particular, collect the following data:</span></p>
            <ul className="ml-6">
            <li><span>- Internet service providers;</span></li>
            <li><span>- IP Address;</span></li>
            <li><span>- HTTP errors;</span></li>
            <li><span>- Information on your equipment, including: your internet connection, the equipment you use to access the Site, including the version and type of browser you use;</span></li>
            <li><span>- Connection time on the Site and duration of the use of the Services;</span></li>
            <li><span>- The links on which you click on the Site;</span></li>
            <li><span>- Standard information regarding the server log, the bandwidth, some software installed on your computer;</span></li>
            <li><span>- Data relating to attacks (IP address, country, browser, email address);</span></li>
            </ul>
          <br/>
          <h3><strong>Cookies</strong></h3>
          <p><span>A cookie is a small file placed on a device's hard drive for recording purposes by a web server. Our Site and our Services may use cookies allowing to recognize the User on her/his subsequent uses of the Site and/or Services and to record the User's clickstream data for many purposes, including: (i) identifying You when You sign-in to our Site, (ii) delivering content, including advertisements, relevant to Your interest, on our Site or on third-party sites, (iii) improving the quality and the navigation on the Site and/or the use of our Services, (iv) creating new features, functionalities and services, (iii) carrying out traffic and statistical analysis.</span></p>
          <p><span>We use in particular the following cookies:</span></p>
          <ul className="ml-6">
          <li><span>- Cookies allowing audience and statistical measurements on the Site and on the App.</span></li>
          </ul>
          <p><span>You are free to block, delete or disable some or all cookies by changing your cookie preferences in your browser settings. However, please note that if you choose to set your browser in this manner, some areas of our Site and/or our Services may not function properly.</span></p>
          <br/>
          <h3><strong>Data relative to your applications</strong></h3>
          <p><span>When you use our Services, Strio collects the data coming from your applications in order to provide you with the Services, including in particular data relating to the performances of your applications, which are mainly technical data (environment, versions, plug-ins used, etc.). Nevertheless, this data may include personal data relating to your own clients using your applications. Please note however that we collect and process the data coming from your applications only in order to (i) provide you with the Services detailed in the conditions described in the Terms of Service, and (ii) to improve our Services. We implement all necessary security measures to protect such personal data against any unauthorized access and modification, disclosure or destruction.</span></p>
          <br/>
          <h2><strong>PURPOSES OF THE DATA PROCESSING</strong></h2>
          <p><span>As a data controller, Strio collects your personal data fairly and lawfully and in accordance with any applicable law (including the General Data Protection Regulation (EU) 2016/679 of the European Parliament and of the Council of 27 April 2016), with our Terms of Services and with any Data Processing Agreement. The main purpose of collecting personal data is to provide you with a safe, optimal, efficient and personalized experience. We may use your personal data to:</span></p>
            <ul className="ml-6">
            <li><span>- Allow you to use our Services and to provide you with related services;</span></li>
            <li><span>- Send you information and meet your requests;</span></li>
            <li><span>- Carry out statistical analyses and develop our Site and Services;</span></li>
            <li><span>- Comply with the current laws and regulations and our Terms of Services.</span></li>
            </ul>
          <p><span>Strio may use the data collected for other purposes, which you will be informed of at the time the data is collected and be asked to accept.</span></p>
          <br/>
          <h2><strong>COMMUNICATION OF YOUR DATA</strong></h2>
          <p><span>Strio is committed to keeping all personal data collected via the Site and the Services and to sharing it only in restricted circumstances and pursuant to the provisions of the applicable law.</span></p>
          <p><span>Strio may share with third-parties aggregated data that does not identify you directly and that will allow Strio to develop new Services, products, content, advertisements, on the Site to meet your needs and wishes.</span></p>
          <p><span>Strio may also share your personal data with service providers (</span><span>"Sub-Processors"</span><span>) to carry out services related to the Site and/or our Services, including maintenance, hosting, analysis, marketing, online-payment, communication, data processing, distribution of emails, etc in accordance with any Data Processing Agreement executed between You and Strio. These service providers will only have access to your data to provide these services and shall be bound by the same obligations of security and confidentiality as Strio.</span></p>
          <p><span>Strio may share your data to prevent, detect, investigate and take measures against any activities potentially prohibited or illegal, any situation threatening the security or any other right and interest of Users or any individual, any breach of the Terms of Service, if authorized or required by law and if Strio considers in good faith that it is necessary to share them. Besides, Strio may send your personal data to respond to legal proceedings of any nature, Court orders, any legal action or implementing enforcement measures that are required by the competent authorities.</span></p>
          <p><span>Strio may only share your personal data for other purposes with your prior consent.</span></p>
          <br/>
          <h2><strong>TRANSFER OF YOUR PERSONAL DATA</strong></h2>
          <p><span>As indicated above, your personal data may be transferred and/or otherwise made available to third-party service providers, including outside of the European Economic Area, in order to provide the Services or if it is required by applicable regulation exclusively.</span></p>
          <p><span>Strio will do so in accordance with any Data Processing Agreement executed with you and will systematically require such third-parties to take, in accordance with applicable regulations, all appropriate technical and organizational measures to safeguard the security and confidentiality of personal data and, in particular, conditions allowing to ensure adequate protection of your personal data.</span></p>
          <br/>
          <h2><strong>DATA RETENTION</strong></h2>
          <p><span>Strio retains your personal data for the period necessary to provide the Services and to answer your requests and in accordance with the applicable law and statute of limitation obligations.</span></p>
          <br/>
          <h2><strong>SECURITY</strong></h2>
          <p><span>We implement all necessary security measures in accordance with this Privacy Policy, any Data Processing Agreement and applicable regulations for the protection of personal data, to protect Strio, You and the Users against any unauthorized access and modification, disclosure or destruction of the data we hold.</span></p>
          <br/>
          <h2><strong>COLLECTION OF YOUR DATA BY THIRD PARTIES</strong></h2>
          <p><span>The Site may contain links towards other sites that we do not control.</span></p>
          <p><span>We are not responsible for the data privacy and collection practices or the content of such sites. Editors of such sites are not required to comply with this Privacy Policy. If these sites include terms of services or any other privacy policy, you must read them carefully as the use you will make of these sites will be subject to such policies.</span></p>
          <br/>
          <h2><strong>YOUR RIGHTS</strong></h2>
          <p><span>If you are established in the EEA or in Switzerland, you have the rights to:</span></p>
            <ul className="ml-6">
            <li><span>- access and correct your personal information. If you want to review or correct your personal information, you can login to Your Account or send us an email at the address noted in the Contact Information section below.</span></li>
            <li><span>- request deletion of any personal information we hold about you. If you do so, we will erase this information so long as this information is no longer necessary in relation to the purpose for which it was processed and there are no overriding legitimate grounds for the processing.</span></li>
            <li><span>- request a copy of your personal information, where: we are relying upon your consent or the fact that the processing is necessary for the performance of a contract to which you are party as the legal basis for the processing, and when personal information is processed by automatic means.</span></li>
            </ul>
          <p><span>To exercise these rights, you can send us an email at the address noted in the Contact Information section below, enclosing a copy of an identity document and the address our response should be sent to.</span></p>
          <br/>
          <h2><strong>Contact information</strong></h2>
          <p><span>Please send us an email at </span><span>support@strio.app</span></p>  
        </div>      
      </section>

    </Layout>
  );
};
